import React from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';

import {
  Layout,
  BlogPostTags,
  BlogPrevNext,
  BlogHeaderMeta,
  PostSiderail,
  ShareButtons
} from '../components/index';
import { withPrefix, htmlToReact, Constants, classNames } from '../utils';
import { Helmet } from 'react-helmet';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { S9comment } from 'gatsby-plugin-social9-comment';
import { ImArrowUp } from '@react-icons/all-files/im/ImArrowUp';
import ThemeContext from '../context/ThemeContext';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String) {
    sitePage(path: { eq: $url }) {
      id
    }
  }
`;

export default class Post extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.listenToScroll = this.listenToScroll.bind(this);
    // this.getSectionsAndAddIds = this.getSectionsAndAddIds.bind(this);
    this.state = {
      scrolled: 0,
      showScrollButton: false,
      // authorFirst: _.get(this.props, 'pageContext.frontmatter.author.first_name', null),
      // authorLast: _.get(this.props, 'pageContext.frontmatter.author.last_name', null),
      difficulty: _.get(this.props, 'pageContext.frontmatter.difficulties', null),
      type: _.get(this.props, 'pageContext.frontmatter.post_type', null),
      readTime: _.get(this.props, 'pageContext.readingTime.text', null),
      categories: _.get(this.props, 'pageContext.frontmatter.categories', null),
      postDate: _.get(this.props, 'pageContext.frontmatter.date', null),
      location: _.get(this.props, 'location.href', null)
    };
  }

  listenToScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    this.setState({
      scrolled: scrolled * 100,
      showScrollButton: winScroll > 300
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Layout {...this.props}>
            <Helmet>
              <meta
                property="og:title"
                content={_.get(this.props, 'pageContext.frontmatter.title', null)}
              />
              <meta
                property="og:description"
                content={_.get(this.props, 'pageContext.frontmatter.excerpt', null)}
              />
              <meta
                property="og:image"
                content={_.get(this.props, 'pageContext.frontmatter.image', null)}
              />
              <meta
                property="og:url"
                content={`${Constants.urlPrefix}${_.get(this.props, 'uri', null)}`}
              />
              <meta
                name="twitter:title"
                content={_.get(this.props, 'pageContext.frontmatter.title', null)}
              />
              <meta
                name="twitter:description"
                content={_.get(this.props, 'pageContext.frontmatter.excerpt', null)}
              />
              <meta
                name="twitter:image"
                content={_.get(this.props, 'pageContext.frontmatter.image', null)}
              />
              <meta name="twitter:card" content="summary_large_image" />
              <meta
                name="description"
                content={
                  this.props.pageContext?.frontmatter?.excerpt ||
                  'Another article about internet privacy and security.'
                }
              />
              {!!this.state.authorFirst && (
                <meta
                  name="author"
                  content={`${this.state.authorFirst} ${this.state.authorLast || ''}`}
                />
              )}
            </Helmet>
            <div className="post-progress-container">
              <div
                className="post-progress"
                style={{
                  width: `${this.state.scrolled}%`,
                  backgroundColor: '#fe7f2d',
                  height: '.5rem',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 1000
                }}
              />
            </div>
            <button
              className={`scroll-to-top-button ${
                this.state.showScrollButton ? 'show-scroll' : 'hide-scroll'
              }`}
              onClick={() => scrollTo('#site-wrap')}
            >
              <div>
                <ImArrowUp alt="arrow up icon" />
              </div>
            </button>
            <article className={classNames('post', { dark: theme.dark })}>
              <div className="post--inner">
                <div className="siderail siderail-left" />
                <div className="container container--md">
                  {_.get(this.props, 'pageContext.frontmatter.image', null) && (
                    <div className="post__image">
                      <img
                        src={withPrefix(_.get(this.props, 'pageContext.frontmatter.image', null))}
                        alt={_.get(this.props, 'pageContext.frontmatter.image_alt', null)}
                      />
                    </div>
                  )}
                  <header className="post__header">
                    <h1 className="post__title">
                      {_.get(this.props, 'pageContext.frontmatter.title', null)}
                    </h1>
                    {/* <div className="post__meta info"> */}
                    <BlogHeaderMeta
                      difficulty={this.state.difficulty}
                      type={this.state.type}
                      readTime={this.state.readTime}
                      categories={this.state.categories}
                      postDate={this.state.postDate}
                    />
                    {/* {_.get(this.props, 'pageContext.frontmatter.author', null) &&
                    (() => {
                      let author = _.get(this.props, 'pageContext.frontmatter.author', null);
                      return author.link ? (
                        <span>
                          {' '}
                          &nbsp; by{' '}
                          <Link to={withPrefix(author.link)}>
                            {author.first_name} {author.last_name}
                          </Link>
                        </span>
                      ) : (
                        <span>
                          {' '}
                          by {author.first_name} {author.last_name}
                        </span>
                      );
                    })()} */}
                    {/* </div> */}
                  </header>
                  <div className="post__copy">
                    {htmlToReact(_.get(this.props, 'pageContext.html', null))}
                  </div>
                  {_.get(this.props, 'pageContext.frontmatter.tags', null) && (
                    <footer className="post__footer">
                      <BlogPostTags
                        {...this.props}
                        tags={_.get(this.props, 'pageContext.frontmatter.tags', null)}
                      />
                    </footer>
                  )}
                  <div className="share-buttons-footer-wrapper">
                    <ShareButtons
                      location={this.state.location}
                      title={this.props.pageContext.frontmatter.title || 'Tinfoil My Life'}
                      shareTitle={true}
                    />
                  </div>
                  <BlogPrevNext currentPage={this.props.pageContext} />
                  <div className="comments-container">
                    <S9comment />
                  </div>
                </div>
                <div className="siderail siderail-right">
                  <PostSiderail
                    pages={_.get(this.props, 'pageContext.pages', null)}
                    current={this.props.pageContext}
                    location={this.state.location}
                  />
                </div>
              </div>
            </article>
          </Layout>
        )}
      </ThemeContext.Consumer>
    );
  }
}
